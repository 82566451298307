import React, { useState, useRef, useEffect, useCallback, memo } from "react"
import {
  ListTLink,
  transitionFadeOut,
  transitionFadeIn,
} from "../components/layout"
import * as artiklerStyles from "../styles/artikler.module.css"
import JamStack from "../assets/jamStack.inline.svg"
import Pwa from "../assets/PWA.inline.svg"
import SeoSvg from "../assets/seo.inline.svg"
import Svg from "../assets/svg.inline.svg"
import ArticleCoverImage from "../components/articleCoverImages"

export const Article = memo(function ({
  data,
  slug,
  cover_image,
  cover_image_alt,
  title,
  description,
  articleActive,
}) {
  let link = useRef(null)
  let image = useRef(null)
  const [activeState, setActiveState] = useState(articleActive)

  useEffect(() => {
    setActiveState(articleActive)
    if (articleActive) {
      link.imageTarget = image
      link.addEventListener("touchmove", onTouchMove, { passive: true })
      image.imageTarget = image
      image.parentElement.addEventListener("touchmove", onTouchMove, {
        passive: true,
      })
    } else {
      link.removeEventListener("touchmove", onTouchMove)
      image.removeEventListener("touchstart", onTouchMove)
    }
  }, [articleActive]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTouchMove = useCallback(event => {
    let hoveredElement = document.querySelector(".hover")
    if (
      hoveredElement !== event.currentTarget.imageTarget.parentElement &&
      hoveredElement !== null
    ) {
      hoveredElement.classList.remove("hover")
    }
    event.currentTarget.imageTarget.parentElement.classList.add("hover")
  }, [])
  if (!slug) {
    return null
  }

  if (cover_image === "SEO") {
    return (
      <>
        <div>
          <div
            ref={el => (image = el)}
            className={
              activeState
                ? artiklerStyles.box
                : artiklerStyles.box + " " + artiklerStyles.fadeOut
            }
          >
            <ListTLink
              label={title}
              to={slug}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <SeoSvg />
            </ListTLink>
          </div>
        </div>
        <div
          ref={el => (link = el)}
          className={
            activeState
              ? artiklerStyles.articleDesc
              : artiklerStyles.articleDesc + " " + artiklerStyles.fadeOut
          }
        >
          <ListTLink
            label={title}
            to={slug}
            exit={{
              length: 0.4,
              trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
            }}
            entry={{
              delay: 0.4,
              appearAfter: 0.4,
              length: 0.4,
              trigger: ({ entry, node }) => transitionFadeIn(node),
            }}
          >
            <h2>{title}</h2>
          </ListTLink>
          <p style={{ paddingBottom: "3rem" }}>{description}</p>
        </div>
      </>
    )
  } else if (cover_image === "SVG") {
    return (
      <>
        <div>
          <div
            ref={el => (image = el)}
            className={
              activeState
                ? artiklerStyles.box
                : artiklerStyles.box + " " + artiklerStyles.fadeOut
            }
          >
            <ListTLink
              label={title}
              to={slug}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <Svg />
            </ListTLink>
          </div>
        </div>
        <div
          ref={el => (link = el)}
          className={
            activeState
              ? artiklerStyles.articleDesc
              : artiklerStyles.articleDesc + " " + artiklerStyles.fadeOut
          }
        >
          <ListTLink
            label={title}
            to={slug}
            exit={{
              length: 0.4,
              trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
            }}
            entry={{
              delay: 0.4,
              appearAfter: 0.4,
              length: 0.4,
              trigger: ({ entry, node }) => transitionFadeIn(node),
            }}
          >
            <h2>{title}</h2>
          </ListTLink>
          <p style={{ paddingBottom: "3rem" }}>{description}</p>
        </div>
      </>
    )
  } else if (cover_image === "PWA") {
    return (
      <>
        <div>
          <div
            ref={el => (image = el)}
            className={
              activeState
                ? artiklerStyles.box
                : artiklerStyles.box + " " + artiklerStyles.fadeOut
            }
          >
            <ListTLink
              label={title}
              to={slug}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <Pwa />
            </ListTLink>
          </div>
        </div>
        <div
          ref={el => (link = el)}
          className={
            activeState
              ? artiklerStyles.articleDesc
              : artiklerStyles.articleDesc + " " + artiklerStyles.fadeOut
          }
        >
          <ListTLink
            label={title}
            to={slug}
            exit={{
              length: 0.4,
              trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
            }}
            entry={{
              delay: 0.4,
              appearAfter: 0.4,
              length: 0.4,
              trigger: ({ entry, node }) => transitionFadeIn(node),
            }}
          >
            <h2>{title}</h2>
          </ListTLink>
          <p style={{ paddingBottom: "3rem" }}>{description}</p>
        </div>
      </>
    )
  } else if (cover_image === "JAM") {
    return (
      <>
        <div>
          <div
            ref={el => (image = el)}
            className={
              activeState
                ? artiklerStyles.box
                : artiklerStyles.box + " " + artiklerStyles.fadeOut
            }
          >
            <ListTLink
              label={title}
              to={slug}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <JamStack />
            </ListTLink>
          </div>
        </div>
        <div
          ref={el => (link = el)}
          className={
            activeState
              ? artiklerStyles.articleDesc
              : artiklerStyles.articleDesc + " " + artiklerStyles.fadeOut
          }
        >
          <ListTLink
            label={title}
            to={slug}
            exit={{
              length: 0.4,
              trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
            }}
            entry={{
              delay: 0.4,
              appearAfter: 0.4,
              length: 0.4,
              trigger: ({ entry, node }) => transitionFadeIn(node),
            }}
          >
            <h2>{title}</h2>
          </ListTLink>
          <p style={{ paddingBottom: "3rem" }}>{description}</p>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div style={{ maxHeight: "385px" }}>
          <div
            ref={el => (image = el)}
            className={
              activeState
                ? artiklerStyles.boxNosvg
                : artiklerStyles.boxNosvg + " " + artiklerStyles.fadeOut
            }
          >
            <ListTLink
              label={title}
              to={slug}
              exit={{
                length: 0.4,
                trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
              }}
              entry={{
                delay: 0.4,
                appearAfter: 0.4,
                length: 0.4,
                trigger: ({ entry, node }) => transitionFadeIn(node),
              }}
            >
              <div
                style={{ maxWidth: "300px", width: "75vw", overflow: "hidden" }}
              >
                <ArticleCoverImage
                  style={{ width: "75vw", maxWidth: "300px" }}
                  fileName={cover_image}
                  alt={cover_image_alt}
                />
              </div>
            </ListTLink>
          </div>
        </div>
        <div
          ref={el => (link = el)}
          className={
            activeState
              ? artiklerStyles.articleDesc
              : artiklerStyles.articleDesc + " " + artiklerStyles.fadeOut
          }
        >
          <ListTLink
            label={title}
            to={slug}
            exit={{
              length: 0.4,
              trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
            }}
            entry={{
              delay: 0.4,
              appearAfter: 0.4,
              length: 0.4,
              trigger: ({ entry, node }) => transitionFadeIn(node),
            }}
          >
            <h2>{title}</h2>
          </ListTLink>
          <p style={{ paddingBottom: "3rem" }}>{description}</p>
        </div>
      </>
    )
  }
})

const ArticleIndex = ({ data, taglist, articleList, articleActive }) => {
  let taglistIndexMatch = []

  taglist.forEach(tag => {
    articleList.forEach((article, idx) => {
      let articleIndex = idx
      article.node.frontmatter.tags.forEach(articleTag => {
        let tagIndex = tag.indexOf(articleTag)
        if (tagIndex !== -1) {
          taglistIndexMatch.push(articleIndex)
        }
      })
    })
  })
  taglistIndexMatch = [...new Set(taglistIndexMatch)]

  let matchedArticlelist = []
  let listedArticles

  taglistIndexMatch.forEach(index => {
    matchedArticlelist[index] = articleList[index].node.frontmatter
  })

  listedArticles = matchedArticlelist.map((listArticle, index) => (
    <Article
      data={data}
      cover_image_alt={listArticle.cover_image_alt}
      articleActive={articleActive}
      slug={listArticle.slug}
      cover_image={listArticle.cover_image}
      title={listArticle.title}
      description={listArticle.description}
    />
  ))

  return <div>{listedArticles}</div>
}

export default ArticleIndex
