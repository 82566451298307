import React, { useState, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import Sticker from "./sticker.js"
import Plus from "../assets/plus-circle.inline.svg"
import Minus from "../assets/minus-circle.inline.svg"
import * as styles from "../styles/sticker.module.css"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "./soundToggle.js"

const StickerIndex = ({ taglist, UpdateTaglist, setArticleActiveState }) => {
  const { language } = useI18next()
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  const [activeState, setActiveState] = useState(false)

  let dataLanguage

  const setStickersActive = e => {
    setActiveState(false)
    setArticleActiveState(true)
    play()
    UpdateTaglist()
  }

  const setStickersInactive = e => {
    play()
    setActiveState(true)
    fade()
    setArticleActiveState(false)
    setTimeout(function () {
      UpdateTaglist()
    }, 400)
  }

  const data = useStaticQuery(
    graphql`
      query {
        en_production: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/articles/en/" }
            frontmatter: { published: { eq: true } }
          }
        ) {
          group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
          }
        }
        da_production: allMarkdownRemark(
          filter: {
            fileAbsolutePath: { regex: "/articles/da/" }
            frontmatter: { published: { eq: true } }
          }
        ) {
          group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
          }
        }
        en_development: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/articles/en/" } }
        ) {
          group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
          }
        }
        da_development: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/articles/da/" } }
        ) {
          group(field: frontmatter___tags) {
            tag: fieldValue
            totalCount
          }
        }
      }
    `
  )

  const fade = () => {
    setArticleActiveState(false)
  }

  const handleActiveState = () => {
    fade()
    setTimeout(function () {
      UpdateTaglist()
      setArticleActiveState(true)
    }, 600)
  }

  const languageString =
    process.env.NODE_ENV === "development"
      ? `${language}_development`
      : `${language}_production`

  dataLanguage = data[languageString].group

  const tags = dataLanguage.map((data, index) => (
    <Sticker
      activeState={activeState}
      handleActiveState={handleActiveState}
      label={data.tag}
      index={index}
      aria-label={t("STICKER_TOGGLE")}
      title={t("STICKER_TOGGLE")}
    />
  ))

  return (
    <>
      <p class="stylizedCapitalized">{t("STICKER_CATEGORY_HEADLINE")}</p>
      <hr style={{ marginTop: "0" }} />
      <div className="stickersBox">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "4px",
          }}
        >
          <button
            onClick={e => setStickersActive(e)}
            className="stickersButton"
            style={{ height: "fit-content", width: "fit-content" }}
            aria-label={t("STICKERS_SELECT_ALL")}
            title={t("STICKERS_SELECT_ALL")}
          >
            <Plus width="24px" className={styles.plus} />
          </button>
          <button
            onClick={e => setStickersInactive(e)}
            className="stickersButton"
            aria-label={t("STICKERS_DESELECT_ALL")}
            title={t("STICKERS_DESELECT_ALL")}
          >
            <Minus width="24px" className={styles.minus} />
          </button>
        </div>
        {tags}
      </div>
      <hr />
    </>
  )
}

export default StickerIndex
