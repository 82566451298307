import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import * as artiklerStyles from "../styles/artikler.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import PreviousArrow from "../components/previousArrow"
import LanguageSwitch from "../components/languageSwitch"
import StickerIndex from "../components/stickerIndex"
import ThemeSwitch from "../components/themeSwitch"
import SoundButton from "../components/soundToggle"
import ArticleIndex from "../components/articleIndex"
import Page06 from "../assets/page06.inline.svg"
import Seo from "../components/seo"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { gsap } from "gsap/dist/gsap"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations"
import ScrollTopArrow from "../components/backToTop"
import useDeviceDetect from "../components/deviceDetect"

const Artikler = ({ location, data }) => {
  const { language } = useI18next()
  const [articleActive, setarticleActive] = useState(true)
  const setArticleActiveState = newState => {
    setarticleActive(newState)
  }

  const prefersReducedMotion = usePrefersReducedMotion()
  const [count, setCount] = useState(0)
  useEffect(() => {
    setCount(prevCount => prevCount + 1)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, [isMobile])

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='cover']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    if (prefersReducedMotion === true) {
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
  }, [prefersReducedMotion])

  let dataLanguage

  let articleList

  if (language === "en") {
    dataLanguage = data.en.group
    articleList = data.en.edges
    //filter list to only show published articles in production
    const allowedPosts = articleList.filter(
      post =>
        process.env.NODE_ENV === "development" ||
        post.node.frontmatter.published
    )
    articleList = allowedPosts
  } else {
    dataLanguage = data.da.group
    articleList = data.da.edges
    //filter list to only show published articles in production
    const allowedPosts = articleList.filter(
      post =>
        process.env.NODE_ENV === "development" ||
        post.node.frontmatter.published
    )
    articleList = allowedPosts
  }

  let firstTaglist = []
  dataLanguage.forEach(language => {
    firstTaglist.push(language.tag)
  })

  const [taglist, setTaglist] = useState(firstTaglist)

  const UpdateTaglist = () => {
    let stickers = document.querySelectorAll(
      ".stickersBox [aria-pressed='true'] p"
    )

    if (count !== 0) {
      let updatedTaglist = []
      stickers.forEach(sticker => {
        updatedTaglist.push(sticker.id)
      })
      setTaglist(updatedTaglist)
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <Seo
        title={t("ARTIKLER.SEO_TITLE")}
        description={t("ARTIKLER.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="SoMe/open_graph_articles.png"
        readingTime="1"
      />
      <PreviousArrow
        to={language === "da" ? `/kompetencer/` : `/${language}/kompetencer/`}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page06 className={artiklerStyles.pageNumber} />
          <h1 className={artiklerStyles.Display1}>{t("ARTIKLER.DISPLAY")}</h1>
          <h2>{t("ARTIKLER.HEADLINE")}</h2>
          <p>{t("ARTIKLER.BODY_TEXT")}</p>
          <BlobGradient className={artiklerStyles.blob1} />
          <Dotpattern className={artiklerStyles.dotPattern1} />
        </section>
        <section className="card right">
          <Blob className={artiklerStyles.blob2} />
          <BlobClip className={artiklerStyles.blob3} />
          <div className={artiklerStyles.cover} />
          <Blob className={artiklerStyles.blob4} />
          <Dotpattern className={artiklerStyles.dotPattern2} />
        </section>
        <section className="card wide" style={{ overflow: "visible" }}>
          <StickerIndex
            setArticleActiveState={setArticleActiveState}
            UpdateTaglist={UpdateTaglist}
            taglist={taglist}
          />
        </section>
        <section className={artiklerStyles.cardComic}>
          <ArticleIndex
            data={data}
            articleActive={articleActive}
            articleList={articleList}
            taglist={taglist}
          />
        </section>
        <ScrollTopArrow showBelow={700} />
      </main>
    </>
  )
}

export default Artikler

export const queryData = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    en: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/articles/en/" } }
    ) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
      edges {
        node {
          frontmatter {
            tags
            cover_image
            cover_image_alt
            description
            title
            slug
            published
          }
        }
      }
    }
    da: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/articles/da/" } }
    ) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
      edges {
        node {
          frontmatter {
            tags
            cover_image
            cover_image_alt
            description
            title
            slug
            published
          }
        }
      }
    }
  }
`
