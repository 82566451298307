import React, { useState, useEffect } from "react"
import Chevron from "../assets/chevron.inline.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ScrollTopArrow = ({ showBelow }) => {
  const { t } = useTranslation()
  const [showScroll, setShowScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
    return () => {
      window.removeEventListener("scroll", checkScrollTop)
    }
  })
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > showBelow) {
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= showBelow) {
      setShowScroll(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }
  return (
    <button
      className="scrollTop"
      aria-label={t("SCROLL_TO_TOP")}
      title={t("SCROLL_TO_TOP")}
      onClick={scrollTop}
      style={{
        height: 40,
        display: showScroll ? "block" : "block",
        animation: showScroll
          ? "appear ease-out 0.4s"
          : "disappear ease-in 0.4s",
        animationFillMode: showScroll ? "forwards" : "both",
      }}
    >
      <Chevron style={{ transform: "rotate(-90deg) translateY(-1px)" }} />
    </button>
  )
}

export default ScrollTopArrow
