// extracted by mini-css-extract-plugin
export var Sticker = "sticker-module--Sticker--HazS1";
export var active = "sticker-module--active--uhVv2";
export var anim = "sticker-module--anim--2KG2a";
export var back = "sticker-module--back--3rd3D";
export var circle = "sticker-module--circle--p9O5q";
export var circle_wrapper = "sticker-module--circle_wrapper--ka05X";
export var front = "sticker-module--front--79PHR";
export var mediumLabel = "sticker-module--mediumLabel--0LolU";
export var minus = "sticker-module--minus--A2Ajd";
export var plus = "sticker-module--plus--EP4ha";
export var reveal = "sticker-module--reveal--qACIF";
export var sticky = "sticker-module--sticky--tE2v4";