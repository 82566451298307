// extracted by mini-css-extract-plugin
export var Berrydance = "artikler-module--Berrydance--Tmcho";
export var Display1 = "artikler-module--Display1--KKQDU";
export var articleDesc = "artikler-module--articleDesc--pqAG0";
export var blob1 = "artikler-module--blob1--rVjAD";
export var blob2 = "artikler-module--blob2--Rhuk-";
export var blob3 = "artikler-module--blob3--DAaSR";
export var blob4 = "artikler-module--blob4--8bQxN";
export var box = "artikler-module--box--ygQr3";
export var boxNosvg = "artikler-module--boxNosvg--maPV2";
export var cardComic = "artikler-module--cardComic--QRurz";
export var cover = "artikler-module--cover--XubgF";
export var dotPattern1 = "artikler-module--dotPattern1--ATDwT";
export var dotPattern2 = "artikler-module--dotPattern2--6KSlx";
export var fadeIn = "artikler-module--fadeIn--i2evf";
export var fadeOut = "artikler-module--fadeOut--SEIhg";
export var jamdance = "artikler-module--jamdance--4xeTr";
export var kapow = "artikler-module--kapow--L4RMs";
export var kapow2 = "artikler-module--kapow2--tMSCb";
export var lightning = "artikler-module--lightning--s8L0O";
export var pageNumber = "artikler-module--pageNumber--I5u9o";
export var sun = "artikler-module--sun--lRpCB";
export var sun2 = "artikler-module--sun2--NbSq7";