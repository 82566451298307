import React, { useState, useEffect, useRef, useContext } from "react"
import * as styles from "../styles/sticker.module.css"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle"

const Sticker = ({ label, activeState, handleActiveState }) => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [isActive, setActive] = useState(activeState)
  //const sticker = createRef()

  useEffect(() => {
    handleActiveState()
  }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

  const loaded = useRef(false)
  useEffect(() => {
    if (loaded.current) {
      setActive(activeState)
    } else {
      loaded.current = true
    }
  }, [activeState])

  const handleClickFunction = e => {
    play()
    e.preventDefault()
    setActive(!isActive)
  }

  const clickSticker = e => {
    e.preventDefault()
    e.currentTarget.click()
  }

  const keyListenersMap = new Map([
    [32, clickSticker],
    [13, clickSticker],
  ])
  function keyListener(e, index) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener && listener(e, index)
  }

  return (
    <>
      <div
        role="button"
        aria-labelledby={label}
        aria-pressed={!isActive}
        tabIndex="0"
        onClick={e => handleClickFunction(e)}
        onKeyPress={e => keyListener(e)}
        className={
          isActive
            ? `${styles.active + " " + styles.anim + " " + styles.Sticker}`
            : `${styles.anim + " " + styles.Sticker}`
        }
      >
        <div className={styles.reveal + " " + styles.circle_wrapper}>
          <div className={styles.circle}>{label}</div>
        </div>

        <div className={styles.sticky + " " + styles.anim}>
          <div
            className={
              styles.front + " " + styles.circle_wrapper + " " + styles.anim
            }
          >
            <div className={styles.circle + " " + styles.anim}></div>
          </div>
        </div>

        <p id={label} className={styles.mediumLabel}>
          {label}
        </p>

        <div className={styles.sticky + " " + styles.anim}>
          <div
            className={
              styles.back + " " + styles.circle_wrapper + " " + styles.anim
            }
          >
            <div className={styles.circle + " " + styles.anim}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sticker
